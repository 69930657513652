import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomerDataEditComponent } from './customer-data-edit.component';
import { CommonModule } from '@angular/common';
import { UiCommonModule } from '@ui/common';
import { UiFormControlModule } from '@ui/form-control';
import { UiSelectModule } from '@ui/select';
import { UiIconModule } from '@ui/icon';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { UiDateInputDirective } from '@ui/input';
import { validateCompanyOrPersonalInfoRequired } from '../../validators/gender-b2b-validator';

@Component({
  selector: 'page-customer-data-edit-b2b',
  templateUrl: 'customer-data-edit-b2b.component.html',
  styleUrls: ['customer-data-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    UiCommonModule,
    UiFormControlModule,
    UiSelectModule,
    UiIconModule,
    ReactiveFormsModule,
    RouterLink,
    UiDateInputDirective,
  ],
})
export class CustomerDataEditB2BComponent extends CustomerDataEditComponent {
  afterInitForm = (control: FormGroup) => {
    control.get('lastName').setValidators([]);
    control.get('firstName').setValidators([]);
    control.setValidators([validateCompanyOrPersonalInfoRequired]);
  };
}
