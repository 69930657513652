import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, inject } from '@angular/core';
import { CheckboxComponent } from '@shared/components/checkbox';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '@shared/components/select';
import { FormControlComponent } from '@shared/components/form-control';
import { CrmCustomerService } from '@domain/crm';
import { AddressDTO, Gender, ShippingAddressDTO } from '@generated/swagger/crm-api';
import { map, takeUntil } from 'rxjs/operators';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { AddressSelectionModalService } from '@modal/address-selection';
import { CustomerSearchStore } from '../store';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { Subject, combineLatest } from 'rxjs';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon';
import { zipCodeValidator } from '../../validators/zip-code-validator';
import { GenderSettingsService } from '@shared/services/gender';
import { validateCompanyOrPersonalInfoRequired } from '../../validators/gender-b2b-validator';

@Component({
  selector: 'page-add-shipping-address-main-view',
  templateUrl: 'add-shipping-address-main-view.component.html',
  styleUrls: ['add-shipping-address-main-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-add-shipping-address-main-view' },
  imports: [
    RouterLink,
    IconComponent,
    AsyncPipe,
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    SelectModule,
    FormControlComponent,
    CheckboxComponent,
  ],
})
export class AddShippingAddressMainViewComponent implements OnInit, OnDestroy {
  private _customerService = inject(CrmCustomerService);
  private _addressSelection = inject(AddressSelectionModalService);
  private _store = inject(CustomerSearchStore);
  private _navigation = inject(CustomerSearchNavigation);
  public genderSettings = inject(GenderSettingsService);

  private _onDestroy = new Subject<void>();

  detailsRoute$ = combineLatest([this._store.processId$, this._store.customerId$]).pipe(
    map(([processId, customerId]) => this._navigation.detailsRoute({ processId, customerId })),
  );

  formGroup = new FormGroup({
    gender: new FormControl<Gender>(undefined, [Validators.required]),
    title: new FormControl<string>(undefined),
    firstName: new FormControl<string>(undefined, [Validators.required]),
    lastName: new FormControl<string>(undefined, [Validators.required]),
    organisation: new FormControl<string>(undefined),
    department: new FormControl<string>(undefined),
    vatId: new FormControl<string>(undefined),
    street: new FormControl<string>(undefined, [Validators.required]),
    streetNumber: new FormControl<string>(undefined, [Validators.required]),
    zipCode: new FormControl<string>(undefined, [Validators.required, zipCodeValidator()]),
    city: new FormControl<string>(undefined, [Validators.required]),
    country: new FormControl<string>('DEU', [Validators.required]),
    info: new FormControl<string>(undefined),
    isDefault: new FormControl<boolean>(false),
  });

  countries$ = this._customerService.getCountries().pipe(map((res) => res.result));

  isBusinessKonto$ = this._store.isBusinessKonto$;

  ngOnInit() {
    this._store.customer$.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      // Dynamic validation rules based on account type
      // For business accounts (isBusinessKonto), we apply different validation rules
      // than for personal accounts
      if (this._store.isBusinessKonto) {
        // For business accounts:
        // - Clear individual validators from personal info fields
        // - Add a form-level validator that requires either company OR personal info
        // - Make address fields optional to support different business address formats
        this.formGroup.controls.organisation.setValidators([]);
        this.formGroup.controls.gender.setValidators([]);
        this.formGroup.controls.firstName.setValidators([]);
        this.formGroup.controls.lastName.setValidators([]);
        this.formGroup.setValidators([validateCompanyOrPersonalInfoRequired]);
        this.formGroup.controls.street.setValidators([]);
        this.formGroup.controls.streetNumber.setValidators([]);
      } else {
        // For personal accounts:
        // - Organization info is optional
        // - First and last name are required
        // - No special form-level validators needed
        this.formGroup.controls.organisation.clearValidators();
        this.formGroup.controls.firstName.setValidators([Validators.required]);
        this.formGroup.controls.lastName.setValidators([Validators.required]);
        this.formGroup.setValidators([]);
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  async save() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    try {
      this.formGroup.disable();

      const formData = this.formGroup.value;

      const address: AddressDTO = {
        street: formData.street,
        streetNumber: formData.streetNumber,
        zipCode: formData.zipCode,
        city: formData.city,
        country: formData.country,
        info: formData.info,
      };

      const addressValidationResult = await this._addressSelection.validateAddress(address);

      if (addressValidationResult === undefined) {
        this.formGroup.enable();
        return;
      }

      const addOrganization = this._store.isBusinessKonto || formData.organisation !== undefined;

      const shippingAddress: ShippingAddressDTO = {
        gender: formData.gender,
        title: formData.title,
        firstName: formData.firstName,
        lastName: formData.lastName,
        organisation: addOrganization
          ? {
              name: formData.organisation,
              department: formData.department,
              vatId: formData.vatId,
            }
          : undefined,
        address: addressValidationResult,
      };

      const result = await this._customerService.createShippingAddress(
        this._store.customerId,
        shippingAddress,
        formData.isDefault,
      );

      this._navigation.navigateToDetails({
        processId: this._store.processId,
        customerId: this._store.customerId,
      });
    } catch (error) {
      this.formGroup.enable();
    }
  }
}
